import React from "react"
import useTranslations from "../../utils/useTranslations"
import FeaturesList from "../../components/featuresList"
import ComparisonPanels from "../../components/comparisonPanels"
import { CompatibilityContext } from "../../components/compatibilityContext";

import heroImgWebp from "../../images/products/heroes/ui-web.webp"
import heroImg from "../../images/products/heroes/ui-web.png"
import heroImgWebp2x from "../../images/products/heroes/ui-web@2x.webp"
import heroImg2x from "../../images/products/heroes/ui-web@2x.png"

import Button from "../../components/button"
import Logo from "../../components/logo"
import LocalizedLink from "../../components/localizedLink"
import backgrounds from "../../components/backgrounds"
import TipsFaq from "../../components/tipsFaq"

const heroImgAlt = "Download µTorrent Web"

const proLink = "https://gateway.lavasoft.com/utorrentpro/cart/"

class BittorrentWebFree extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMac: false
    };
  }
  handleWebProClick(e, action, link) {
    this.context(e, action, 'Web', ['Win'], link)
  }

  render(){

    const { text, pageName } = this.props.pageContext
    const t = useTranslations(text)
    const { unlocalizedPath }  = this.props.pageContext
    
    return (
      <>

        <div className="pt-md-2">

          <div className="container text-dark py-2">

            <div className="d-flex flex-column justify-content-center text-center mt-4 my-sm-4">
              <div className="col-md-12 mt-md-4 order-md-1 px-md-0">
                <Logo secondaryColor="black" tag="h1" productName="Web" className="mb-4"/>
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-sm-2"></img>*/}

                <picture className="d-flex justify-content-center align-items-start">
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none shadow-lg rounded-xl"></img>
                </picture>
                <div style={{fontSize: '10px'}} className="d-block d-md-none mt-2 mb-4"><a id="web-bt-now-mobile" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>

                <h1 className="text-size-24 text-spacing-1 font-weight-normal">{t('Play torrents while you download.')}</h1>
                <h2 className="text-size-16 text-spacing-1 font-weight-normal my-3">{t('Easy to use. Works inside your favorite browser')}</h2>
                
              <div>
                  <Button  href={`${unlocalizedPath}#comparison-panels`} id="products-win-utweb-free-hero-cta" className=" btn-primary text-spacing-1 mt-3 mt-md-3 mb-4 button-filled text-spacing-1">{t(`Free Download`)}</Button>
              </div>

              </div>
              
            </div>
            <div className="col-md-12 d-none d-md-block mb-5 order-md-2">
                {/*<img src={heroImg} alt="µTorrent Web in-client" className="img-fluid d-none d-md-block"></img>*/}

                <picture className="d-flex justify-content-center">
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt="µTorrent Web in-client" className="img-fluid d-none d-md-block shadow-lg rounded-xl"></img>
                </picture>
                <div style={{fontSize: '10px'}} className="d-none d-md-block mb-4 text-center mt-2"><a id="web-bt-now" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>

              </div>
          </div>
        </div>

        <div className="container-fluid position-relative overflow-hidden">
          <div className="container z-2">

            <div className="container d-flex justify-content-center align-items-center py-2 px-0 my-4 mt-sm-5">
                <div className="col-12 order-md-1 d-flex flex-column justify-content-center align-items-center">
                  <h2 className="font-weight-bold text-md-left feature-list-title">{t(`FeaturesHeader`)}</h2>
                  <p className="font-weight-normal mb-3 mt-3 text-center text-md-left feature-list-title-description">{t(`µTorrent Web Online Torrent Downloader Features`)}</p>
                </div>
              </div>

            <div className = "index-features-list m-auto pb-5">
              <FeaturesList text={text} pageName={pageName}/>
            </div>
          </div>
        </div>


        <div className="container-fluid position-relative overflow-hidden px-0 mx-0" style={backgrounds.lightBg}>
        <div className="container-fluid panel-bg">  
          <div className="container my-5 text-center z-2">
            <p className="text-center font-weight-bold feature-list-title mb-3 pt-3">{t(`Compare`)}</p>
            <h2 className="text-center mb-5 text-size-24 font-weight-normal text-dark">
                {t(`Compare µTorrent Web Versions`)}
            </h2>
          </div>
          <ComparisonPanels pageContext={{ "text": text, "pageName":pageName, "isMac": this.state.isMac}}>
            <p className="text-dark text-center mt-3 mt-sm-5">
              <LocalizedLink to="/web/pro/" id="products-win-utweb-free-learnmore-premium" className="font-weight-bold">
                {t(`Learn more`)}
              </LocalizedLink> {t(`about µTorrent Web premium products`)}
            </p>
          </ComparisonPanels>
        </div>
        </div>

        <TipsFaq type="faq" header={t(`FAQ`)} subheader={t(`µTorrent Web Frequently Asked Questions`)}>
          <div className="col-12 px-0">
            <p className="product-faq-question">{t(`What is a web torrent client?`)}</p>
            <p className="product-faq-answer">{t(`Unlike client software that runs in a separate window on your Windows computer, a web torrent client lives in a web browser. Web torrent clients are still software that need to be downloaded, however once installed they can help you download torrents from a web browser like Google Chrome. Web torrent clients typically have a simpler interface and are easier to use than desktop software, so they are ideal for newer users.`)}</p>
            <p className="product-faq-answer">{t(`µTorrent Web, which is also known as uTWeb, is the most popular web torrent client in the world due to its simple design and ease of use. The software launches in your preferred web browser (Chrome, Firefox or Opera). Once you find a torrent to download, µTorrent Web helps you play the file near-instantaneously if it’s a video or audio file. This is especially helpful to quickly validate the quality of the torrent before you download the complete file. Any files you download from the web torrent client are stored in the folder of your choice for offline viewing or consumption.`)}</p>
            <p className="product-faq-question">{t(`Does uTWeb download torrent magnet links?`)}</p>
            <p className="product-faq-answer">{t(`Yes. µTorrent Web enables fast download of torrent files and magnet links. We recommend using the safe torrent scanner, which is offered to you shortly after installing µTorrent Web. When you search Google, Yahoo or Bing, the scanner helps you identify both torrent files and torrent magnet links directly on the search results page. The scanner enables you to click on a torrent file or magnet link from within the search results, meaning you never need to visit potentially harmful sites. If you click a torrent magnet link, uTWeb will begin downloading the file and, if it’s a video or audio file, will play it within your web browser.`)}</p>
            <p className="product-faq-question">{t(`I’m looking for a torrent downloader online. Is µTorrent Web the right choice?`)}</p>
            <p className="product-faq-answer">{t(`Yes. µTorrent Web is a browser-based torrent client that allows you to download torrents online. Once installed, µTorrent Web works in a new tab of your favorite browser (Chrome, Firefox, or Opera) and allows you to download any file type into the folder of your choice. The online torrent downloader has many advantages over desktop-based software, including a simpler interface, less customization options to learn, quick media playback, and an experience that is integrated more tightly with your online workflows.`)}</p>
          </div>
        </TipsFaq>


      </>
    )

  }
}

BittorrentWebFree.contextType = CompatibilityContext;

export default BittorrentWebFree
